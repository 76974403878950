html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Urbanist', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: white;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

* {
  box-sizing: border-box;
  outline: transparent;
}

ul {
  list-style: disc;
  margin-left: 20px;
}

.explore-more-btn-price {
  &:focus,
  &:active,
  &:hover {
    background-color: #ef8c3013 !important;
    color: #ef8c30 !important;
  }
}

.explore-more-btn-visibility {
  &:focus,
  &:active,
  &:hover {
    background-color: #8950fc13 !important;
    color: #8950fc !important;
  }
}

.explore-more-btn-content {
  &:focus,
  &:active,
  &:hover {
    background-color: #2563eb13 !important;
    color: #2563eb !important;
  }
}

.explore-more-btn-rating-review {
  &:focus,
  &:active,
  &:hover {
    background-color: #56b28213 !important;
    color: #56b282 !important;
  }
}

.explore-more-btn-e-retail-media {
  &:focus,
  &:active,
  &:hover {
    background-color: #d2253713 !important;
    color: #d22537 !important;
  }
}

.explore-more-btn-location-based-analytics {
  &:focus,
  &:active,
  &:hover {
    background-color: #ef8c3013 !important;
    color: #ef8c30 !important;
  }
}
