.swiper {
  display: flex !important;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  overflow: auto !important;

  &-pagination {
    position: relative !important;
    display: flex;
    align-items: center;
    justify-content: center;
    left: unset !important;
    transform: none !important;
    overflow: unset !important;
    overflow-x: hidden !important;
    padding: 2rem;
    color: var(--swiper-theme-color) !important;

    &-bullet {
      flex-shrink: 0;
      overflow: unset !important;
      width: 20px !important;
      height: 20px !important;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: currentColor;
        border-radius: 50px;
        opacity: 0.2;
        transition: 0s;
      }

      &-active {
        &::before {
          transform: scale(1.5);
        }
      }
    }
  }
}

.input {
  &:focus {
    border-color: #ef8c30 !important;
  }

  &:active {
    border-color: #ef8c30 !important;
  }

  &:focus-within {
    border-color: #ef8c30 !important;
  }

  &:focus-visible {
    outline: none !important;
    border-color: #ef8c30 !important;
  }

  &:target {
    border-color: #ef8c30 !important;
  }

  &.error {
    border-color: #ef8c30 !important;
  }
}

.title-animation {
  animation: moveInLeft 1s ease-in-out;
}

.description-animation {
  animation: moveInLeftWithoutBounce 1s ease-in-out;
}

@keyframes moveInLeftWithoutBounce {
  0% {
    color: white;
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.image-animation {
  animation: moveInRight 1s ease-in-out;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.tag-scale {
  animation: tagScale 1s ease-in-out;
}

@keyframes tagScale {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

select.arrow {
  background-image: url('../../public/images/solutions/contact-form/arrow.svg');
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
}

select.arrow:focus,
select.arrow:active,
select.arrow:focus-visible {
  background-image: url('../../public/images/solutions/contact-form/arrow-focus.svg');
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.image-pulse-animation {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
